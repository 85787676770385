import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { animated } from "react-spring";
import Link from "../components/library/Link";

import { primaryColorDark, primaryColor, mq } from "../theme";
import TextInput from "../components/library/TextInput";
import TextArea from "../components/library/TextArea";
import Form from "../components/library/Form";
import { useFade } from "../transitions";

const heroWrapperStyles = css`
  max-width: 900px;
  margin: auto;

  ${mq("medium")} {
    display: flex;
    margin-bottom: 32px;
  }
`;

const heroStyles = css`
  width: 100%;
  height: auto;

  & > div {
    ${mq("medium")} {
      height: 30vw;
      min-height: 350px;
      max-height: 400px;
    }
  }
`;

const heroInfoStyles = css`
  padding: 0 56px;

  ${mq("medium")} {
    display: flex;
    justify-content: center;
  }
`;

const contentWrapperStyles = css`
  max-width: 600px;
  margin: auto;
`;

const formContainerStyles = css`
  margin: 0 24px 24px;
`;

const testimonialContainerStyles = css`
  padding: 16px;
`;

const testimonialStyles = css`
  margin: 16px 0;

  .content {
    text-align: center;
    font-weight: 300;
    font-size: 18px;

    ${mq("medium")} {
      text-align: left;
    }
  }

  .source {
    margin-top: 4px;

    text-align: right;

    font-size: 12px;

    &:before {
      content: "- ";
    }
  }
`;

const faqContainerStyles = css`
  padding: 16px;
`;

const faqStyles = css`
  h2 {
    margin-bottom: 8px;

    font-weight: 600;
    font-size: 20px;
    color: ${primaryColorDark};
  }

  & > div > p {
    margin: 0;

    font-size: 16px;
    color: ${primaryColor};
  }
`;

const Index = ({ data, transitionStatus }) => {
  const [personName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [comments, setComments] = useState("");

  const fade = useFade(transitionStatus);

  const faqs = data.faqs.nodes
    .sort((a, b) => {
      const aOrder = a.childMarkdownRemark.frontmatter.order;
      const bOrder = b.childMarkdownRemark.frontmatter.order;

      if (!aOrder) {
        return 1;
      } else if (!bOrder) {
        return -1;
      }

      return aOrder - bOrder;
    })
    .map((node) => {
      return {
        title: node.childMarkdownRemark.frontmatter.title,
        content: node.childMarkdownRemark.html,
      };
    });

  const testimonials = data.testimonials.nodes
    .filter((node) => node.childMarkdownRemark.frontmatter.show)
    .map((node) => ({
      content: node.childMarkdownRemark.frontmatter.content,
      source: node.childMarkdownRemark.frontmatter.source,
    }));

  return (
    <animated.div style={fade}>
      <div style={fade} css={heroWrapperStyles}>
        <div css={heroStyles}>
          <Img
            imgStyle={{
              objectPosition: "center 35%",
            }}
            fluid={data.hero.fluid}
          />
        </div>
      </div>
      <div css={heroInfoStyles}>
        <div>
          <h2 className="mb0">
            <strong>Irish Goldendoodle Puppies</strong>
          </h2>

          <p
            css={css`
              margin: 4px 0;
            `}
          >
            Where smarts, loyalty, and cuteness collide
          </p>
          <span
            css={css`
              font-size: 12px;
              color: ${primaryColor};
            `}
          >
            Available near Chicago, St. Louis, and Indianapolis
          </span>
          <ul>
            <li>Family Friendly</li>
            <li>Hypoallergenic</li>
            <li>Easy Going</li>
            <li>Highly trainable</li>
          </ul>
          <Link
            to="/puppies"
            className="button primary"
            css={css`
              width: 128px;
              margin: auto;
            `}
          >
            View Puppies
          </Link>
        </div>
      </div>
      <div css={contentWrapperStyles}>
        <div
          css={css`
            text-align: center;
            margin: 0 24px;
          `}
        >
          <h2
            css={css`
              margin-bottom: 0;
            `}
          >
            Interested?
          </h2>
          <h3
            css={css`
              margin: 0;
            `}
          >
            Let's get in touch.
          </h3>
          <p className="light">
            Because we care so much about the puppies we breed, we’d like to get
            to know you a little better before you become home to one of our
            doodles.{" "}
          </p>
        </div>
        <div css={formContainerStyles}>
          <Form
            name="home-page-contact-form"
            completedEl={
              <>
                <h2>Thanks for your enquiry</h2>
                <h3>We'll get back to you as soon as we can</h3>
              </>
            }
          >
            {({ submitting, submitted }) => (
              <div
                css={{
                  paddingTop: 16,
                }}
              >
                <TextInput
                  name="personName"
                  label="Name*"
                  required
                  disabled={submitting || submitted}
                  onChange={(e) => setName(e.target.value)}
                  value={personName}
                />
                <TextInput
                  name="email"
                  label="Email"
                  disabled={submitting || submitted}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <div
                  css={{
                    display: "flex",
                  }}
                >
                  <TextInput
                    name="personPhone"
                    label="Phone"
                    disabled={submitting || submitted}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  <TextInput
                    name="personCity"
                    label="City"
                    disabled={submitting || submitted}
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </div>
                <TextArea
                  required
                  name="personComments"
                  label="Comments/Questions*"
                  disabled={submitting || submitted}
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                />

                <button
                  disabled={submitting || submitted}
                  css={{
                    margin: "auto",
                  }}
                  className="primary"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
        </div>
        {testimonials.length > 0 && (
          <div css={testimonialContainerStyles}>
            <h2>Testimonials</h2>
            {testimonials.map((testimonial, i) => {
              return (
                <div css={testimonialStyles} key={i}>
                  <div className="content">{testimonial.content}</div>
                  <div className="source">{testimonial.source}</div>
                </div>
              );
            })}
          </div>
        )}
        <div css={faqContainerStyles}>
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, i) => {
            return (
              <div key={i} css={faqStyles}>
                <h2>{faq.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: faq.content }} />
              </div>
            );
          })}
          <h2 className="mb0">Have another question?</h2>
          <p className="light">
            If you have another question, we’d be glad to help you get an
            answer. Fill out the form above, and we’ll get back to you as soon
            as we can!
          </p>
        </div>
      </div>
    </animated.div>
  );
};

export default Index;

export const query = graphql`
  query IndexQuery {
    faqs: allFile(
      filter: {
        internal: { mediaType: { eq: "text/markdown" } }
        sourceInstanceName: { eq: "faqs" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            title
            order
          }
          html
        }
      }
    }
    testimonials: allFile(
      filter: {
        internal: { mediaType: { eq: "text/markdown" } }
        sourceInstanceName: { eq: "testimonial" }
      }
    ) {
      nodes {
        sourceInstanceName
        childMarkdownRemark {
          frontmatter {
            source
            content
            show
          }
        }
      }
    }
    hero: imageSharp(fluid: { originalName: { eq: "hero-bed.jpg" } }) {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
